import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: null,
};

const nameReducer = createSlice({
  name: 'name',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    logout: (state) => {
      state = initialState;
    },
  },
});

export const selectUserName = (state) => {
  if (state?.user?.name) {
    return state.user.name.split(' ')[1];
  }
  return null;
};

export const selectUserInitials = (state) => {
  if (state?.user?.name) {
    const [lastName, firstName, middleName] = state.user.name.split(' ');
    return `${lastName} ${firstName.charAt(0)}. ${middleName.charAt(0)}.`;
  }
  return null;
};

export const selectName = (state) => state.user.name;

export const { setName, logout } = nameReducer.actions;

export default nameReducer.reducer;
