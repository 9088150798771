import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { useDispatch } from 'react-redux';
import { getBankDetails, setBankDetails } from '../../../api/profile';
import { useUnsavedChangesWarning } from '../../../shared/utils/useUnsavedChangesWarning';
import { addNotification } from '../../../shared/store/slices/notificationSlice';

import styles from './styles.module.scss';

export const Details = () => {
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const dispatch = useDispatch();
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        getBankDetails()
          .then((data) => {
            if (data) {
              setValue('bic', data?.bic || '');
              setValue('inn', data?.inn || '');
              setValue('accountNumber', data?.accountNumber || '');
              setIsDirty(false);
            }
          })
          .catch((error) => {
            dispatch(addNotification(error.message));
          });
    }, []);

    useUnsavedChangesWarning(isDirty);

    const onSubmit = (data) => {
        setBankDetails(data)
            .then((response) => {
                dispatch(addNotification(response));
                setIsDirty(false);
            })
            .catch((error) => {
                dispatch(addNotification(error.message));
            });
    };

    useEffect(() => {
      const subscription = watch((value, { name }) => {
        if (name) {
          setIsDirty(true);
        }
      });
  
      return () => subscription.unsubscribe();
    }, [watch]);

    const handleInputChange = (e, maxLength) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > maxLength) {
            value = value.slice(0, maxLength);
        }
        e.target.value = value;
        setValue(e.target.name, value);
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Данные банковских реквизитов</h2>

        <div className={`baseField ${errors.bic ? 'baseFieldErrorLine' : ''}`}>
            <label htmlFor="bic">БИК</label>
            <input
                id="bic"
                {...register('bic', {
                    required: 'Это поле обязательно',
                    pattern: {
                        value: /^\d{9}$/,
                        message: 'Введите корректный БИК (9 цифр)',
                    },
                })}
                placeholder="Введите БИК"
                inputMode="numeric"
                maxLength={9}
                onChange={(e) => handleInputChange(e, 9)}
            />
            {errors.bic && <p className='baseFieldError'>{errors.bic.message}</p>}
        </div>

        <div className={`baseField ${errors.accountNumber ? 'baseFieldErrorLine' : ''}`}>
            <label htmlFor="accountNumber">Номер счета</label>
            <input
                id="accountNumber"
                {...register('accountNumber', {
                    required: 'Это поле обязательно',
                    pattern: {
                        value: /^\d{20}$/,
                        message: 'Введите корректный номер счета (20 цифр)',
                    },
                })}
                placeholder="Введите номер счета"
                inputMode="numeric"
                maxLength={20}
                onChange={(e) => handleInputChange(e, 20)}
            />
            {errors.accountNumber && <p className='baseFieldError'>{errors.accountNumber.message}</p>}
        </div>

        <h2>Налоговая информация</h2>
        <div className={`baseField ${errors.inn ? 'baseFieldErrorLine' : ''}`}>
            <label htmlFor="inn">Личный ИНН</label>
            <input
                id="inn"
                {...register('inn', {
                    required: 'Это поле обязательно',
                    pattern: {
                        value: /^\d{12}$/,
                        message: 'Введите корректный ИНН (12 цифр)',
                    },
                })}
                placeholder="Введите ИНН"
                inputMode="numeric"
                maxLength={12}
                onChange={(e) => handleInputChange(e, 12)}
            />
            {errors.inn && <p className='baseFieldError'>{errors.inn.message}</p>}
        </div>

        <div className={styles.button}>
          <Button type="submit">Сохранить</Button>
        </div>
      </form>
    );
};
