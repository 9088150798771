import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../shared/store/slices/notificationSlice';
import { setPassword } from '../../../api/profile';
import { useUnsavedChangesWarning } from '../../../shared/utils/useUnsavedChangesWarning';

import styles from './styles.module.scss';

export const Security = () => {
    const [isDirty, setIsDirty] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
      const subscription = watch((value, { name }) => {
        if (name) {
          setIsDirty(true);
        }
      });
  
      return () => subscription.unsubscribe();
    }, [watch]);
  
    useUnsavedChangesWarning(isDirty);

    const onSubmit = (data) => {
        setPassword(data)
            .then((response) => {
                dispatch(addNotification(response));
                setIsDirty(false);
            })
            .catch((error) => {
                dispatch(addNotification(error.message));
            });
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Защита аккаунта</h2>

        <div className={`baseField ${errors.password ? 'baseFieldErrorLine' : ''}`}>
            <label htmlFor="password">Пароль</label>
            <input
                id="password"
                type="password"
                {...register('password', {
                    required: 'Это поле обязательно',
                    minLength: {
                        value: 8,
                        message: 'Пароль должен содержать минимум 8 символов',
                    },
                })}
                placeholder="Введите текущий пароль"
                onInput={(e) => {
                    setValue('password', e.target.value);
                }}
            />
            {errors.password && <p className='baseFieldError'>{errors.password.message}</p>}
        </div>

        <div className={`baseField ${errors.newPassword ? 'baseFieldErrorLine' : ''}`}>
            <label htmlFor="newPassword">Новый пароль</label>
            <input
                id="newPassword"
                type="password"
                {...register('newPassword', {
                    required: 'Это поле обязательно',
                    minLength: {
                        value: 8,
                        message: 'Пароль должен содержать минимум 8 символов',
                    },
                })}
                placeholder="Введите новый пароль"
                onInput={(e) => {
                    setValue('newPassword', e.target.value);
                }}
            />
            {errors.newPassword && <p className='baseFieldError'>{errors.newPassword.message}</p>}
        </div>

        <div className={`baseField ${errors.newPasswordConfirmation ? 'baseFieldErrorLine' : ''}`}>
            <label htmlFor="newPasswordConfirmation">Подтверждение нового пароля</label>
            <input
                id="newPasswordConfirmation"
                type="password"
                {...register('newPasswordConfirmation', {
                    required: 'Это поле обязательно',
                    validate: (value) => {
                        const { newPassword } = getValues();
                        return newPassword === value || 'Пароли не совпадают';
                    },
                })}
                placeholder="Подтвердите новый пароль"
                onInput={(e) => {
                    setValue('newPasswordConfirmation', e.target.value);
                }}
            />
            {errors.newPasswordConfirmation && <p className='baseFieldError'>{errors.newPasswordConfirmation.message}</p>}
        </div>
  
        <div className={styles.button}>
          <Button type="submit">Сохранить</Button>
        </div>
      </form>
    );
}
