import { sendAuthRequest } from ".";
import { AgentsDataMapper, AgentDataMapper } from "../mappers/agentsMapper";

const AGENTS_URL = '/api/profile/agents';
const AGENT_URL = '/api/profile/agent';

export const getAgents = async () => {
    const response = await sendAuthRequest(AGENTS_URL, 'GET');
    return AgentsDataMapper.toDomainModel(response);
};

export const getAgent = async (id) => {
    const response = await sendAuthRequest(`${AGENT_URL}/${id}`, 'GET');
    return AgentDataMapper.toDomainModel(response);
};