export class UserDataMapper {
    static toDomainModel(apiData) {
        return {
            id: apiData?.user_id,
            balance: parseFloat(apiData?.balance),
            balanceAllTime: parseFloat(apiData?.balance_all_time),
            clientCount: apiData?.client_count,
            agentCount: apiData?.agent_count,
            frozenBalance: parseFloat(apiData?.frozen_balance),
            subscriptionStatus: apiData?.subscription_status,
            subscriptionDate: apiData?.subscription_date,
        };
    }

    static toRequestDTO(userData) {
        return {
            user_id: userData.id,
            balance: userData.balance,
            balance_all_time: userData.balanceAllTime,
            client_count: userData.clientCount,
            agent_count: userData.agentCount,
            frozen_balance: userData.frozenBalance,
            subscription_status: userData.subscriptionStatus,
            subscription_date: userData.subscriptionDate,
        };
    }
}
