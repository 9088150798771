export class PersonalDataMapper {
    static toDomainModel(apiData) {
        return {
            name: apiData?.name,
            birthdate: apiData?.birthdate,
            passport: apiData?.series_passport + apiData?.number_passport,
            issueDate: apiData?.issue_date,
        };
    }

    static toRequestDTO(personalData) {
        return {
            name: personalData.name,
            birthdate: personalData.birthdate,
            series_passport: personalData.passport.slice(0, 4),
            number_passport: personalData.passport.slice(4),
            issue_date: personalData.issueDate,
        };
    }
}

export class SocialMediaMapper {
    static toDomainModel(apiData) {
        return {
            tg: apiData?.tg,
            vk: apiData?.vk,
        };
    }

    static toRequestDTO(socialMediaObject) {
        return {
            tg: socialMediaObject.tg ? socialMediaObject.tg : undefined,
            vk: socialMediaObject.vk ? socialMediaObject.vk : undefined,
        };
    }
}

export class CardMapper {
    static toDomainModel(apiData) {
        return {
            accountNumber: apiData?.bank_acnt,
            bic: apiData?.bank_bik,
            inn: apiData?.inn,
        };
    }

    static toRequestDTO(details) {
        return {
            bank_acnt: details.accountNumber ? details.accountNumber : undefined,
            bank_bik: details.bic ? details.bic : undefined,
            inn: details.inn ? details.inn : undefined,
        };
    }
}

export class PasswordMapper {
    static toRequestDTO(passwordObject) {
        return {
            password: passwordObject.password,
            new_password: passwordObject.newPassword,
            new_password_confirmation: passwordObject.newPasswordConfirmation,
        };
    }
}
