import { sendAuthRequest } from ".";

const API_URL = '/api';

export const loginUser = async (phone, password) => {
    return await sendAuthRequest(`${API_URL}/login`, 'POST', { phone, password });
};

export const logoutUser = async () => {
    return await sendAuthRequest(`${API_URL}/logout`, 'POST');
};

export const getVerificationCode = async (phone) => {
    return await sendAuthRequest(`${API_URL}/verify-code`, 'POST', { phone });
};

export const registerUser = async (phone, verificationCode, password, passwordConfirmation, ref) => {
    const response =  await sendAuthRequest(`${API_URL}/register`, 'POST', 
        {
            phone,
            verification_code: verificationCode,
            password,
            password_confirmation: passwordConfirmation,
            ref: ref ? ref : undefined,
        }
    );
    return response?.access_token
};

export const resetPassword = async (phone, verificationCode, password) => {
    const response =  await sendAuthRequest(`${API_URL}/forgot-password`, 'POST', { phone, verifyCode: verificationCode, password });
    return response?.access_token
};
