import { getAuthToken } from "../repository";

const apiUrl = process.env.REACT_APP_API_URL;

export const parseError = (errorMessage) => {
    try {
      const parsedError = JSON.parse(errorMessage);
      
      if (parsedError.errors) {
        return Object.values(parsedError.errors)
          .flat()
          .join(", ");
      }
  
      return parsedError.error || errorMessage;
    } catch (e) {
      return errorMessage;
    }
};

export const sendRequest = async (url, method, data = null, headers = {}) => {    
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
    };

    if (data) {
        options.body = JSON.stringify(data);
    }

    const fullUrl = new URL(url, apiUrl);
    const response = await fetch(fullUrl, options);
    
    if (!response.ok) {        
        const errorMessage = await response.text();
        const parsedError = parseError(errorMessage);
        if (response.status === 429) {
            throw new Error("Превышено количество запросов. Пожалуйста, попробуйте снова через некоторое время");
        } else if (parsedError !== "Успешно") {
            throw new Error(parsedError);
        } else {
            return parsedError;
        }
    }

    return response.json();
};

export const sendAuthRequest = async (url, method, data = null) => {
    const token = getAuthToken();
    const authHeaders = {
        'Authorization': `Bearer ${token}`,
    };
    return sendRequest(url, method, data, authHeaders);
};
