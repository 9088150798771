import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeNotification } from '../../shared/store/slices/notificationSlice';

import styles from './styles.module.scss';

const NOTIFICATION_LIFETIME = 5000;

export const Notifications = () => {
  const notifications = useSelector((state) => state.notification.notifications);
  const dispatch = useDispatch();

  const handleRemoveNotification = (id) => {
    dispatch(removeNotification(id));
  };

  return (
    <div className={styles.notifications}>
      {notifications.map((notification) => (
        <NotificationItem 
          key={notification.id} 
          notification={notification} 
          removeNotification={handleRemoveNotification} 
        />
      ))}
    </div>
  );
};

const NotificationItem = ({ notification, removeNotification }) => {
  React.useEffect(() => {
    const timerId = setTimeout(() => {
      removeNotification(notification.id);
    }, NOTIFICATION_LIFETIME);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <div className={styles.notification}>
      {notification.message}
      <button onClick={() => removeNotification(notification.id)}>×</button>
    </div>
  );
};
