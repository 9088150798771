import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { Popup } from '../../components/Popup';
import { VideoPlayer } from '../../components/VideoPlayer';
import { setTariff } from '../../api/tariff';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../shared/store/slices/notificationSlice';

import styles from './styles.module.scss'

export const TariffPage = () => {
  const [video, setVideo] = useState(null);

  const dispatch = useDispatch();

  const changeTariff = (status) => {
    setTariff(status)
        .then((response) => {
            dispatch(addNotification(response));
        })
        .catch((error) => {
            dispatch(addNotification(error.message));
        });
};

  return (
    <div className={styles.tariffPage}>
      <h1>Тариф</h1>
      <p>Здесь можно написать пояснения к тарифам или вроде того</p>

      <div className={styles.cards}>
        <div className={styles.card}>
          <p className={styles.text}>
            Тариф «Самозанятый» включает в себя годовой пакет с последующей ежегодной пролонгацией.
          </p>
          
          <button className={styles.moreDetailsButton} onClick={() => {setVideo('/')}}>
            Подробнее о тарифе
          </button>

          <span className={styles.price}>
            50 000 ₽/год
          </span>

          <Button onClick={() => changeTariff(1)}>Подключить</Button>
        </div>

        <div className={styles.card}>
          <p className={styles.text}>
            Тариф «Бизнес» включает в себя безлимитный пакет - навсегда.
          </p>
          
          <button className={styles.moreDetailsButton} onClick={() => {setVideo('/')}}>
            Подробнее о тарифе
          </button>

          <span className={styles.price}>
            150 000 ₽/год
          </span>

          <Button onClick={() => changeTariff(2)}>Подключить</Button>
        </div>
      </div>
      {video &&
        <Popup close={() => {setVideo(null)}} onWrapperClick={() => {setVideo(null)}}>
          <div className={styles.video}>
            <VideoPlayer source={video}/>
          </div>
        </Popup>
      }
    </div>
  );
};
