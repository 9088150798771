import React from 'react';

import styles from './styles.module.scss';

export const Popup = ({ children, close, onWrapperClick }) => {

  return (
    <div className={styles.wrapper} onClick={onWrapperClick}>
      <button onClick={close}>✕</button>
      <div onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};
