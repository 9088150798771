import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { formatDateToDDMMYYYY, formatDateToYYYYMMDD, maskDate, maskPhoneOnlyNumber } from '../../../shared/utils/masks';
import { setName } from '../../../shared/store/slices/nameSlice';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../shared/store/slices/notificationSlice';
import { getPersonalInformation, setPersonalInformation } from '../../../api/profile';
import { getPhone } from '../../../repository';
import { useUnsavedChangesWarning } from '../../../shared/utils/useUnsavedChangesWarning';

import styles from './styles.module.scss';

export const MyData = () => {
  const [isDirty, setIsDirty] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getPersonalInformation()
      .then((data) => {
        setValue('fullName', data?.name && data.name || '');
        setValue('birthdate', data?.birthdate && formatDateToDDMMYYYY(data.birthdate) || '');
        setValue('passport', data?.passport &&`${data.passport.slice(0, 4)} ${data.passport.slice(4, 10)}` || '');
        setValue('issueDate', data?.issueDate && formatDateToDDMMYYYY(data.issueDate) || '');
        dispatch(setName(data.name));
        setIsDirty(false);
      })
      .catch((error) => {
        dispatch(addNotification(error.message));
      });
      const phone = getPhone();
      setValue('phone', phone || '');
  }, []);

  useUnsavedChangesWarning(isDirty);
  
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        setIsDirty(true);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    const newData = {
      name: data.fullName,
      passport: data.passport.replace(/\D/g, ''),
      birthdate: formatDateToYYYYMMDD(data.birthdate),
      issueDate: formatDateToYYYYMMDD(data.issueDate),
      phone: maskPhoneOnlyNumber(data.phone),
    };

    setPersonalInformation(newData)
      .then((response) => {
          dispatch(addNotification(response));
          dispatch(setName(data.fullName));
          setIsDirty(false);
      })
      .catch((error) => {
          dispatch(addNotification(error.message));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h2>Персональные данные</h2>

      <div className={`baseField ${errors.fullName ? 'baseFieldErrorLine': ''}`}>
        <label htmlFor="fullName">Фамилия Имя Отчество</label>
        <input
          id="fullName"
          {...register('fullName', {
            required: 'Это поле обязательно',
            pattern: {
              value: /^[А-Яа-яЁёA-Za-z]+\s[А-Яа-яЁёA-Za-z]+\s[А-Яа-яЁёA-Za-z]+$/,
              message: 'Введите корректно ФИО (три слова через пробел)',
            },
          })}
          placeholder=""
        />
        {errors.fullName && <p className='baseFieldError'>{errors.fullName.message}</p>}
      </div>

      <div className={`baseField ${errors.birthdate ? 'baseFieldErrorLine': ''}`}>
        <label htmlFor="birthdate">Дата рождения</label>
        <input
          id="birthdate"
          {...register('birthdate', {
            required: 'Это поле обязательно',
            validate: (value) => {
              return /^\d{2}\.\d{2}\.\d{4}$/.test(value) || 'Введите корректную дату (дд.мм.гггг)';
            },
          })}
          placeholder=""
          onInput={(e) => setValue('birthdate', maskDate(e.target.value))}
        />
        {errors.birthdate && <p className='baseFieldError'>{errors.birthdate.message}</p>}
      </div>

      <div className={`baseField ${errors.passport ? 'baseFieldErrorLine': ''}`}>
        <label htmlFor="passport">Серия и номер паспорта</label>
        <input
          id="passport"
          {...register('passport', {
            required: 'Это поле обязательно',
            pattern: {
              value: /^\d{4}\s\d{6}$/,
              message: 'Введите серию и номер паспорта',
            },
          })}
          placeholder=""
          onInput={(e) => {
            const value = e.target.value.replace(/\D/g, '');
            const formattedValue = value.length > 4 ? `${value.slice(0, 4)} ${value.slice(4, 10)}` : value;
            setValue('passport', formattedValue);
          }}
        />
        {errors.passport && <p className='baseFieldError'>{errors.passport.message}</p>}
      </div>

      <div className={`baseField ${errors.issueDate ? 'baseFieldErrorLine': ''}`}>
        <label htmlFor="issueDate">Дата выдачи паспорта</label>
        <input
          id="issueDate"
          {...register('issueDate', {
            required: 'Это поле обязательно',
            validate: (value) => {
              return /^\d{2}\.\d{2}\.\d{4}$/.test(value) || 'Введите корректную дату (дд.мм.гггг)';
            },
          })}
          placeholder=""
          onInput={(e) => setValue('issueDate', maskDate(e.target.value))}
        />
        {errors.issueDate && <p className='baseFieldError'>{errors.issueDate.message}</p>}
      </div>

      <div className={`baseField`}>
        <label htmlFor="phone">Номер телефона</label>
        <input
          id="phone"
          {...register('phone')}
          placeholder=""
          disabled
        />
      </div>

      <div className={styles.button}>
        <Button type="submit">Сохранить</Button>
      </div>
    </form>
  );
};
