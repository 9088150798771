import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { SideBar } from '../SideBar';

import styles from './styles.module.scss';

export const Layout = () => {

    return (
        <div className={styles.page}>
            <ScrollRestoration />
            <SideBar />
            <div className={styles.content}>
                <Header />
                <main className={styles.main}>
                    <div className={styles.mainInner}>
                        <Outlet />
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
};