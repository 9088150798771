export class FinancesDataMapper {    
    static toDomainModel(apiData) {
        return {
            operations: apiData?.map((operation) => {
                return {
                    date: new Date(operation?.date || null),
                    id: operation?.operations_id,
                    type: operation?.type,
                    status: operation?.status,
                }
            }),
        };
    }
}
