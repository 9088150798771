export class ClientDataMapper {    
    static toDomainModel(apiData) {
        return {
            totalClients: apiData?.total_clients_count,
            clientPaidedContract: apiData?.paid_clients_count,
            clients: apiData?.clients?.map((client) => {
                return {
                    date: new Date(client?.registration_date || null),
                    name: client?.last_name + ' ' + client?.name + ' ' +  client?.second_name,
                    clientId: client?.id,
                    payout: parseFloat(client?.profit),
                    status: client?.status,
                }
            }),
        };
    }
}
