import { useRouteError, Link } from 'react-router-dom';

import styles from './styles.module.scss';

export const ErrorPage = ({ error: passedError }) => {
  const routeError = useRouteError();
  const error = passedError || routeError;

  return (
    <div className={styles.errorPage}>
      <h1>{error?.status || 'Ошибка'}</h1>
      <p>{error?.statusText || 'Что-то пошло не так'}</p>
      {error?.message && <p>Описание ошибки: {error.message}</p>}
      <Link to="/" className={styles.homeLink}>Вернуться на главную страницу</Link>
    </div>
  );
};
