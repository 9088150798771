import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../api/login';
import { addNotification } from '../../shared/store/slices/notificationSlice';
import { NavLink } from 'react-router-dom';
import { ReactComponent as MainIcon } from '../../shared/images/svg/main.svg';
import { ReactComponent as ProfileIcon } from '../../shared/images/svg/profile.svg';
import { ReactComponent as ClientsIcon } from '../../shared/images/svg/clients.svg';
import { ReactComponent as AgentsIcon } from '../../shared/images/svg/agents.svg';
import { ReactComponent as FinancesIcon } from '../../shared/images/svg/finances.svg';
import { ReactComponent as HelpIcon } from '../../shared/images/svg/help.svg';
import { ReactComponent as TariffIcon } from '../../shared/images/svg/tariff.svg';
import { useNavigate } from 'react-router-dom';
import { removeAuthToken, removePhone } from '../../repository';

import styles from './styles.module.scss';

export const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getClassNames = ( { isActive } ) => {
    return `${styles.link} ${isActive ? styles.activeLink : ''}`
  };

  const getOut = () =>{
    try { 
      logoutUser()
        .then(()=> {
          navigate('/login');
          removeAuthToken();
          removePhone();
        });
    } catch (error) {
      dispatch(addNotification(error.message));
    }
  };

  return (
    <aside className={styles.sidebar}>
      <nav className={styles.nav}>
        <NavLink to="/" className={getClassNames}>
          <MainIcon /> Главная
        </NavLink>
        <NavLink to="/profile/info" className={getClassNames}>
          <ProfileIcon /> Профиль
        </NavLink>
        <NavLink to="/clients" className={getClassNames}>
          <ClientsIcon /> Клиенты
        </NavLink>
        <NavLink to="/agents" className={getClassNames}>
          <AgentsIcon /> Агенты
        </NavLink>
        <NavLink to="/finances" className={getClassNames}>
          <FinancesIcon /> Финансы
        </NavLink>
        <NavLink to="/help" className={getClassNames}>
          <HelpIcon /> Помощь
        </NavLink>
        <NavLink to="/tariff" className={getClassNames}>
          <TariffIcon /> Тариф
        </NavLink>
        <div className={styles.logout}>
          <button 
            className={styles.logoutButton}
            onClick={getOut}
          >
            Выход
          </button>
        </div>
      </nav>
      <div className={styles.info}>
        <p>Телефон</p>
        <h3>8-962-790-16-28</h3>
        <p>Центр Обслуживания</p>
        <h3>Ленина, 12</h3>
      </div>
    </aside>
  );
};