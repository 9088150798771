import React, { useEffect, useState } from 'react';
import { ReactComponent as ClientPaidedContractIcon } from '../../shared/images/svg/clientPaidedContract.svg';
import { ReactComponent as TotalClientsIcon } from '../../shared/images/svg/totalClients.svg';
import { BigCard } from '../../components/BigCard';
import { SearchBar } from '../../components/SearchBar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getClients } from '../../api/clients';
import { addNotification } from '../../shared/store/slices/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInitials } from '../../shared/store/slices/nameSlice';
import { formatDate } from '../../shared/utils/masks';
import { formatCurrency } from '../../shared/utils/formatCurrency';

import styles from './styles.module.scss'

const ROW_NUMBER = 50;

export const ClientsPage = () => {
  const [clientPaidedContract, setClientPaidedContract] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [rows, setRows] = useState(ROW_NUMBER);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const dispatch = useDispatch();
  
  const userInitials = useSelector(selectUserInitials);

  const cardsData = [{
    icon: ClientPaidedContractIcon,
    label: 'Оплатившие договор',
    text: clientPaidedContract,
  },{
    icon: TotalClientsIcon,
    label: 'Всего клиентов',
    text: totalClients,
  },];

  useEffect(() => {
    getClients()
      .then((data) => {
        setClientPaidedContract(data.clientPaidedContract);
        setTotalClients(data.totalClients);
        setClients(data.clients.map((client) => ({...client, invitedBy: userInitials})));
      })
      .catch((error) => {
        dispatch(addNotification(error.message));
      });
  }, []);

  useEffect(() => {
    setClients((prevClients) => prevClients.map((client) => ({...client, invitedBy: userInitials})));
  }, [userInitials]);

  const transformDate = (rowData) => {
    const date = new Date(rowData.date);    
    return formatDate(date);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setRows(ROW_NUMBER);
  };

  const handleScroll = () => {
    const bottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
    
    if (bottom) {
      setRows((prevLimit) => prevLimit + ROW_NUMBER);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    setRows(ROW_NUMBER);
  };

  return (
    <div className={styles.clientsPage}>
      <h1>Клиенты</h1>

      <div className={styles.cards}>
        {cardsData.map((data, index) => <BigCard key={index} {...data} />)}
      </div>
      
      <div className={styles.datatable}>
        <div className={styles.search}>
          <SearchBar onSearch={handleSearch}/>
        </div>
        
        <div className={styles.table}>
          <DataTable 
            value={clients.filter((client) => 
                {
                  const nameMatch = client.name.toLowerCase().includes(searchQuery.toLowerCase());
                  const clientIdMatch = client.clientId.toString().includes(searchQuery);
                  return nameMatch || clientIdMatch;
                }
              )}
            className="datatable"
            emptyMessage='Нет клиентов'
            paginator
            rows={rows}
            onSort={handleSort}
            sortField={sortField}
            sortOrder={sortOrder}
          >
            <Column field="date" header="Дата" sortable body={transformDate}/>
            <Column field="name" header="Фамилия Имя Отчество" sortable/>
            <Column field="clientId" header="ID клиента" sortable/>
            <Column field="invitedBy" header="Пригласил" sortable/>
            <Column field="payout" header="Выплачено" sortable body={(data)=>`${formatCurrency(data.payout)} ₽`}/>
            <Column field="status" header="Статус" sortable/>
          </DataTable>
        </div>
      </div>
    </div>
  );
};
