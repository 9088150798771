import React from 'react';
import styles from './styles.module.scss'

export const VideoPlayer = ({ source }) => {
    return (
      <div className={styles.videoPlayer}>
        <video controls>
          <source src={source} type="video/mp4" />
          Ваш браузер не поддерживает воспроизведение видео.
        </video>
      </div>
    );
  };
