import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../../components/Footer';
import { useForm } from 'react-hook-form';
import { maskPhone, maskPhoneOnlyNumber } from '../../shared/utils/masks';
import { Button } from '../../components/Button';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../shared/store/slices/notificationSlice';
import { getVerificationCode, loginUser, registerUser, resetPassword } from '../../api/login';
import { setAuthToken, setPhone } from '../../repository';

import styles from './styles.module.scss';

export const LoginPage = () => {
  const [requestCode, setRequestCode] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get('ref');

  const { register, formState: { errors }, setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      verificationCode: ''
    }
  });

  const isLogin = location.pathname === '/login';
  const isRegistration = location.pathname === '/registration';
  const isPasswordRecovery = location.pathname === '/password-recovery';

  useEffect(() => {
    if (isLogin) {
      setRequestCode(false);
    }
  }, [isLogin]);

  const passwordValue = watch('password');

  const onSubmit = async (data) => {
    try {
      if (isLogin) {
        const responseData = await loginUser(maskPhoneOnlyNumber(data.phone), data.password);
        if (responseData?.access_token) {
          setAuthToken(responseData.access_token);
          setPhone(data.phone);
          navigate('/');
        }
        return;
      }

      if (!isLogin && !requestCode) {
        await getVerificationCode(maskPhoneOnlyNumber(data.phone))
          .then(() => {
            setRequestCode(true);
          })
          return;
      }

      if (isRegistration) {
        const token = await registerUser(maskPhoneOnlyNumber(data.phone), data.verificationCode, data.password, data.confirmPassword, ref);
        if (token) {
          setAuthToken(token);
          setPhone(data.phone);
          navigate('/');
        }
      } else if (isPasswordRecovery) {
        const token = await resetPassword(maskPhoneOnlyNumber(data.phone), data.verificationCode, data.password);
        if (token) {
          setAuthToken(token);
          setPhone(data.phone);
          navigate('/');
        }
      }
    } catch (error) {
      dispatch(addNotification(error.message));
    }
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.wrapper}>
        <div className={styles.loginBlock}>
          <h1>{isRegistration ? 'Регистрация' : isPasswordRecovery ? 'Восстановление пароля' : 'Вход'}</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!requestCode &&
              <>
                <div className={`baseField ${errors.phone ? 'baseFieldErrorLine': ''}`}>
                  <label htmlFor="phone">Номер телефона</label>
                  <input
                    id="phone"
                    {...register('phone', {
                      required: 'Это поле обязательно',
                      pattern: {
                        value: /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
                        message: 'Введите корректный номер телефона',
                      },
                    })}
                    placeholder=""
                    onInput={(e) => setValue('phone', maskPhone(e.target.value))}
                  />
                  {errors.phone && <p className='baseFieldError'>{errors.phone.message}</p>}
                </div>

                <div className={`baseField ${errors.password ? 'baseFieldErrorLine' : ''}`}>
                  <label htmlFor="password">Пароль</label>
                  <input
                      id="password"
                      type="password"
                      {...register('password', {
                          required: 'Это поле обязательно',
                          minLength: {
                              value: 8,
                              message: 'Пароль должен содержать минимум 8 символов',
                          },
                      })}
                      placeholder=""
                      onInput={(e) => {
                          setValue('password', e.target.value);
                      }}
                  />
                  {errors.password && <p className='baseFieldError'>{errors.password.message}</p>}
                </div>
              </>
            }

            {isLogin && (
              <div className={styles.linkRecovery}>
                <Link to="/password-recovery">Забыли пароль?</Link>
              </div>
            )}

            {!isLogin && !requestCode && (
              <div className={`baseField ${errors.confirmPassword ? 'baseFieldErrorLine' : ''}`}>
                <label htmlFor="confirmPassword">Подтвердите пароль</label>
                <input
                    id="confirmPassword"
                    type="password"
                    {...register('confirmPassword', {
                        required: 'Это поле обязательно',
                        validate: (value) => value === passwordValue || 'Пароли не совпадают',
                    })}
                    placeholder=""
                    onInput={(e) => {
                        setValue('confirmPassword', e.target.value);
                    }}
                />
                {errors.confirmPassword && <p className='baseFieldError'>{errors.confirmPassword.message}</p>}
              </div>
            )}

            {requestCode &&
              <div className='baseField'>
                <label htmlFor="verificationCode">Код подтверждения</label>
                <input
                  type="text"
                  id="verificationCode"
                  {...register('verificationCode', { required: 'Это поле обязательно' })}
                  placeholder=""
                />
              </div>
            }

            <div className={styles.button}>
              <Button type="submit">{isRegistration ? 'Зарегистрироваться' : isPasswordRecovery ? 'Восстановить пароль' : 'Войти'}</Button>
            </div>
          </form>

          {isLogin && (
            <div className={styles.texts}>
              <p>
                Ещё нет аккаунта? <Link to="/registration">Зарегистрироваться</Link>
              </p>
              <a
                className={styles.userAgreement}
                href="/docs/offer.pdf" 
                download="Кто такие Агенты ФК Белый слон.pdf"
              >
                Пользовательское соглашение
              </a>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
