import React, { useState } from 'react';
import { copyToClipboard } from '../../shared/utils/copyToClipboard';

import styles from './styles.module.scss'

export const MiddleCard = ({ icon: Icon, label, text, link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (e) => {
    copyToClipboard(e, link);
    setCopied(true);
    
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className={styles.card}>
      <Icon />
      <span className={styles.label}>
        <span className={styles.label}>{label}</span>
        {link && <span className={styles.label}>(нажмите чтобы скопировать)</span>}
      </span>
      {text && <span className={styles.text}>{text}</span>}
      {link && (
        <a
          href={link}
          className={styles.link}
          onClick={handleCopyClick}
        >
          {link}
        </a>
      )}
      {copied && <div className={styles.copiedTooltip}>Ссылка скопирована</div>}
    </div>
  );
};
