import React, { useState } from 'react';
import { VideoPlayer } from '../../components/VideoPlayer';
import { ReactComponent as ArrowIcon } from '../../shared/images/svg/arrow.svg';
import { ReactComponent as TgIcon } from '../../shared/images/svg/tg.svg';
import { ReactComponent as WhatsAppIcon } from '../../shared/images/svg/whatsApp.svg';

import styles from './styles.module.scss'

export const HelpPage = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const data = [{
      title: 'Инструкция по применению',
      video:''
    },{
      title: 'Для чего нужно вводить данные в личном кабинете',
      video:''
    },{
      title: 'За что начислены 1000₽ и как их вывести на карту',
      video:''
    },{
      title: 'Почему аккаунт не подтверждается модератором',
      video:''
    },
  ];

  const handleItemClick = (index) => {
    setSelectedVideo(selectedVideo === index ? null : index);
  };

  return (
    <div className={styles.helpPage}>
      <h1>Клиенты</h1>

      <div className={styles.helpPageInner}>
        <div className={styles.instructions}>
          {data.map((item, index) => (
            <div key={index} className={styles.item}>
              <div
                className={`${styles.title} ${selectedVideo === index && styles.open}`}
                onClick={() => handleItemClick(index)}
              >
                <h3>
                  {item.title}
                </h3>
                <ArrowIcon />
              </div>
              {selectedVideo === index && (
                <div className={styles.video}>
                  <VideoPlayer source={item.video}/>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className={styles.contacts}>
          <span>
            Не нашли ответ на свой вопрос?<br/>
            Мы здесь, чтобы помочь вам!
          </span>
          <div className={styles.links}>
            <a href="https://t.me/+79059290292" target="_blank" rel="noopener noreferrer" className={styles.link}>
              <TgIcon />
            </a>
            <a href="https://wa.me/+79059290292" target="_blank" rel="noopener noreferrer" className={styles.link}>
              <WhatsAppIcon />
            </a>
          </div>
          <a href="tel:+79059290292" className={styles.callButton}>
            Связаться с нами
          </a>
        </div>
      </div>
    </div>
  );
};
