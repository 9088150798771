import React, { useEffect, useState } from 'react';
import { BigCard } from '../../components/BigCard';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { addNotification } from '../../shared/store/slices/notificationSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as ClientsIcon } from '../../shared/images/svg/clients2.svg';
import { ReactComponent as BalanceIcon } from '../../shared/images/svg/balance.svg';
import { formatDate } from '../../shared/utils/masks';
import { formatCurrency } from '../../shared/utils/formatCurrency';
import { getFinances } from '../../api/finances';
import { NavLink } from 'react-router-dom';
import { BalanceChanger } from '../../components/BalanceChanger';
import { getUserStatistics } from '../../api/user';

import styles from './styles.module.scss'

const ROW_NUMBER = 50;

export const FinancesPage = () => {
  const [balance, setBalance] = useState(null);
  const [balanceAllTime, setBalanceAllTime] = useState(null);
  const [operations, setOperations] = useState([]);
  const [rows, setRows] = useState(ROW_NUMBER);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const dispatch = useDispatch();

  const cardsData = [{
    icon: ClientsIcon,
    label: 'Текущий баланс',
    text: `${formatCurrency(balance)} ₽`,
  },{
    icon: BalanceIcon,
    label: 'Заработано за все время',
    text: `${formatCurrency(balanceAllTime)} ₽`
  },];

  const getFinancesData = () => {
    getFinances()
      .then((data) => {
        setOperations(data.operations);
      })
      .catch((error) => {
        dispatch(addNotification(error.message));
      })
  };

  const getBalanceData = () => {
    getUserStatistics()
      .then((data) => {
        setBalance(data.balance);
        setBalanceAllTime(data.balanceAllTime);
      })
      .catch((error) => {
        dispatch(addNotification(error.message));
      })
  };

  useEffect(() => {
    getBalanceData();
    getFinancesData();
  }, []);

  const transformDate = (rowData) => {
    const date = new Date(rowData.date);    
    return formatDate(date);
  };

  const handleScroll = () => {
    const bottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
    
    if (bottom) {
      setRows((prevLimit) => prevLimit + ROW_NUMBER);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    setRows(ROW_NUMBER);
  };

  const onCalculatorClick = () => {
    dispatch(addNotification('Данный функционал находится в разработке и будет доступен позже.'));
  };

  return (
    <div className={styles.financesPage}>
      <h1>Финансы</h1>

      <div className={styles.cards}>
        {cardsData.map((data, index) => <BigCard key={index} {...data} />)}
      </div>

      <div className={styles.cards}>
        <BalanceChanger refreshFinancesData={getFinancesData}/>

        <div className={styles.card}>
          <span className={styles.text}>
            На этом калькуляторе вы можете поиграться и виртуально посчитать свои потенциальные доходы.
          </span>
          <NavLink className={styles.link} onClick={onCalculatorClick}>
            Калькулятор
          </NavLink>
        </div>
      </div>
      
      <div className={styles.datatable}>
        <div className={styles.table}>
          <DataTable
            value={operations}
            className="datatable"
            emptyMessage='Операции отсутствуют'
            paginator
            rows={rows}
            onSort={handleSort}
            sortField={sortField}
            sortOrder={sortOrder}
          >
            <Column field="date" header="Дата" sortable body={transformDate}/>
            <Column field="id" header="ID операции" sortable/>
            <Column field="type" header="Тип операции" sortable/>
            <Column field="status" header="Статус" sortable/>
          </DataTable>
        </div>
      </div>
    </div>
  );
};
