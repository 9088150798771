import { createBrowserRouter } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { Layout } from './components/Layout';
import { ErrorPage } from './pages/ErrorPage';
import { Profile } from './pages/Profile';
import { LoginPage } from './pages/LoginPage';
import { AuthProvider } from './AuthContext';
import { ClientsPage } from './pages/ClientsPage';
import { AgentsPage } from './pages/AgentsPage';
import { AgentPage } from './pages/AgentPage';
import { FinancesPage } from './pages/FinancesPage';
import { HelpPage } from './pages/HelpPage';
import { TariffPage } from './pages/TariffPage';

export const router = createBrowserRouter([
    {
        element: <AuthProvider />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'login',
                element: <LoginPage />,
            },
            {
                path: 'registration',
                element: <LoginPage />,
            },
            {
                path: 'password-recovery',
                element: <LoginPage />,
            },
            {
                path: '/',
                element: <Layout />,
                children: [
                    {
                        path: '',
                        element: <MainPage />,
                    },
                    {
                        path: 'profile/info',
                        element: <Profile />,
                    },
                    {
                        path: 'profile/social-networks',
                        element: <Profile />,
                    },
                    {
                        path: 'profile/details',
                        element: <Profile />,
                    },
                    {
                        path: 'profile/security',
                        element: <Profile />,
                    },
                    {
                        path: 'clients',
                        element: <ClientsPage />,
                    },
                    {
                        path: 'agents',
                        element: <AgentsPage />,
                    },
                    {
                        path: 'agents/:id',
                        element: <AgentPage />,
                    },
                    {
                        path: 'finances',
                        element: <FinancesPage />,
                    },
                    {
                        path: 'help',
                        element: <HelpPage />,
                    },
                    {
                        path: 'tariff',
                        element: <TariffPage />,
                    },
                ],
            },
        ],
    },
]);
