import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotification: (state, action) => {
      const notification = action.payload;
      const id = Date.now();
      state.notifications.push({ id, message: notification });
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter((error) => error.id !== action.payload);
    },
    clearAllNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const { addNotification, removeNotification, clearAllNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
