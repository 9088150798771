import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getPersonalInformation } from './api/profile';
import { setName } from './shared/store/slices/nameSlice';
import { Loader } from './components/Loader';

const AuthContext = createContext();

export const AuthProvider = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation(); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const targetPages = ['/login', '/registration', '/password-recovery'];

    const fetchData = async () => {
      try {
        const data = await getPersonalInformation();

        dispatch(setName(data.name));

        if (targetPages.includes(location.pathname)) {
          navigate('/');
        }

        setLoading(false);
      } catch (error) {

        if (!targetPages.includes(location.pathname)) {
            navigate('/login');
        }

        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, navigate]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ loading }}>
      <Outlet />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};