import { configureStore } from '@reduxjs/toolkit';
import nameReducer from './slices/nameSlice'; 
import notificationReducer from './slices/notificationSlice';

const store = configureStore({
  reducer: {
    user: nameReducer,
    notification: notificationReducer,
  }
});

export default store;
