import React, { useRef, useState } from 'react';
import { ReactComponent as MagnifyingGlassIcon } from '../../shared/images/svg/magnifyingGlass.svg';

import styles from './styles.module.scss';

export const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const inputRef = useRef(null);
  
  const handleSearchClick = () => {
    inputRef.current.focus();
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleResetClick = () => {
    setQuery('');
    onSearch('');
  };

  const handleSubmit = (event) => {
    onSearch(query);
    event.preventDefault();
  };
  
  return (
    <form className={styles.searchContainer} onSubmit={handleSubmit}>
      <div className={styles.searchIcon} onClick={handleSearchClick}>
        <MagnifyingGlassIcon />
      </div>
      <input
        className={styles.inputField}
        onChange={handleInputChange}
        type="text"
        placeholder=""
        value={query}
        ref={inputRef}
      />
      <button className={styles.searchButton} type='submit'>Искать</button>
      <button className={styles.resetButton} onClick={handleResetClick}>Сбросить</button>
    </form>
  );
};
