import { sendAuthRequest } from ".";
import { FinancesDataMapper } from "../mappers/financesMapper";

const FINANCES_URL = '/api/finance';

export const getFinances = async () => {
    const response = await sendAuthRequest(`${FINANCES_URL}/history`, 'GET');
    return FinancesDataMapper.toDomainModel(response);
};

export const debit = async (sum) => {
    const response = await sendAuthRequest(`${FINANCES_URL}/replenishment`, 'POST', {amount: sum});
    return response;
};

export const credit = async (sum) => {
    const response = await sendAuthRequest(`${FINANCES_URL}/withdrawal`, 'POST', {amount: sum});
    return response;
};