const tokenName = process.env.REACT_APP_TOKEN_NAME;

export const setAuthToken = (name) => {
    localStorage.setItem(tokenName, name);
};

export const getAuthToken = ()  => {
    return localStorage.getItem(tokenName)
};

export const removeAuthToken = () => {
    localStorage.removeItem(tokenName);
};

export const setPhone = (phone) => {
    localStorage.setItem('phone', phone);
};

export const getPhone = ()  => {
    return localStorage.getItem("phone");
};

export const removePhone = () => {
    localStorage.removeItem('phone');
};
