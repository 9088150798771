import { useBeforeUnload, useBlocker } from 'react-router-dom';

export const useUnsavedChangesWarning = (isDirty) => {

  useBeforeUnload((event) => {
    if (isDirty) {
      const message = 'На странице остались несохраненные изменения. Вы уверены, что хотите уйти?';
      event.preventDefault();
      event.returnValue = message;
      return message;
    }
    return true;
  }, { enable: isDirty });

  const blocker = ({ currentLocation, nextLocation }) => {
    if (isDirty && currentLocation.pathname !== nextLocation.pathname) {
      const confirmLeave = window.confirm('На странице остались несохраненные изменения. Вы уверены, что хотите уйти?');
      return !confirmLeave;
    }
    return false;
  };

  useBlocker(blocker);
};
