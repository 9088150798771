import React, { useEffect, useState } from 'react';
import { ReactComponent as AgentsIcon } from '../../shared/images/svg/agents2.svg';
import { getAgent } from '../../api/agents';
import { addNotification } from '../../shared/store/slices/notificationSlice';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as VkIcon } from '../../shared/images/svg/vk.svg';
import { ReactComponent as TgIcon } from '../../shared/images/svg/tg.svg';
import { formatCurrency } from '../../shared/utils/formatCurrency';
import { formatDate } from '../../shared/utils/masks';

import styles from './styles.module.scss'

export const AgentPage = () => {
  const [agent, setAgent] = useState({});

  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getAgent(id)
        .then((data) => {
          setAgent(data);
        })
        .catch((error) => {
          dispatch(addNotification(error.message));
        });
    }
  }, [id]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.agentPage}>
      <h1>{agent.name}</h1>

      <div className={styles.agentPageInner}>
        <div className={styles.info}>
          <div className={styles.infoInner}>
            <AgentsIcon />
            <div>
              <h4>
                {agent.name}
              </h4>
              <span>
                {agent.status}
              </span>
            </div>
          </div>
          {agent.vk &&
            <a className={styles.social} href={agent.vk} target="_blank" rel="noopener noreferrer">
              <VkIcon />
              {agent.vk}
            </a>
          }
          {agent.tg &&
            <a className={styles.social} href={agent.tg} target="_blank" rel="noopener noreferrer">
              <TgIcon />
              {agent.tg}
            </a>
          }
        </div>

        <div className={styles.details}>
          <div className={`${styles.column} ${styles.firstColumn}`}>
            <span>
              Дата регистрации:
            </span>
            <span>
              Агентов:
            </span>
            <span>
              Клиентов:
            </span>
            <span>
              Доход за все время:
            </span>
          </div>
          
          <div className={`${styles.column} ${styles.secondColumn}`}>
            <span>
              {agent?.date && formatDate(agent.date)}
            </span>
            <span>
              {agent.totalAgents}
            </span>
            <span>
              {agent.totalClients}
            </span>
            <span>
              {formatCurrency(agent.profit)} ₽
            </span>
          </div>
        </div>

        
        {agent?.curator &&
          <div className={styles.linkWrapper}>
            <NavLink to={`/agents/${agent?.curator}`} className={styles.link}>
              Его куратор
            </NavLink>
          </div>
        }
      </div>

      <h3
        className={styles.back}
        onClick={handleBackClick}
      >
        Назад
      </h3>
    </div>
  );
};
