import { sendAuthRequest } from ".";
import {
    PersonalDataMapper,
    CardMapper, 
    PasswordMapper,
    SocialMediaMapper,
} from '../mappers/profileMapper';

const PROFILE_URL = '/api/profile';

export const getPersonalInformation = async () => {
    const response = await sendAuthRequest(`${PROFILE_URL}/personal_Information`, 'GET');
    return PersonalDataMapper.toDomainModel(response);
};

export const setPersonalInformation = async (data) => {
    return await sendAuthRequest(`${PROFILE_URL}/personal_Information`, 'POST', PersonalDataMapper.toRequestDTO(data));
};

export const getSocialMedia = async () => {
    const response = await sendAuthRequest(`${PROFILE_URL}/social`, 'GET');
    return SocialMediaMapper.toDomainModel(response);
};

export const setSocialMedia = async (data) => {
    return await sendAuthRequest(`${PROFILE_URL}/social`, 'POST', SocialMediaMapper.toRequestDTO(data));
};

export const getBankDetails = async () => {
    const response = await sendAuthRequest(`${PROFILE_URL}/bank_card`, 'GET');
    return CardMapper.toDomainModel(response);
};

export const setBankDetails = async (data) => {
    return await sendAuthRequest(`${PROFILE_URL}/bank_card`, 'POST', CardMapper.toRequestDTO(data));
};

export const setPassword = async (data) => {
    return await sendAuthRequest(`${PROFILE_URL}/safety`, 'POST', PasswordMapper.toRequestDTO(data));
};