export const maskDate = (value) => {
    const digits = value.replace(/\D/g, '');
    if (digits.length === 0) return '';
    if (digits.length < 3) return digits.slice(0, 2);
    if (digits.length < 5) return `${digits.slice(0, 2)}.${digits.slice(2)}`;
    return `${digits.slice(0, 2)}.${digits.slice(2,4)}.${digits.slice(4, 8)}`;
};

export const maskPhone = (value) => {
    const digits = value.replace(/\D/g, '');
    let formattedPhone = `+7 (${digits.slice(1, 4)}${digits.slice(4, 5) ? ')' : ''}`
    formattedPhone += digits.slice(4, 7) ? ` ${digits.slice(4, 7)}${digits.slice(7, 9) ? '-' : ''}${digits.slice(7, 9)}${digits.slice(9, 11) ? '-' : ''}${digits.slice(9, 11)}` : '';
    return formattedPhone;
};
export const maskPhoneOnlyNumber = (value) => {
    return value.replace(/\D/g, '').replace(/\d/, '8');
};

export const formatDateToDDMMYYYY = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}.${month}.${year}`;
};

export const formatDateToYYYYMMDD = (dateString) => {
    const [day, month, year] = dateString.split('.');
    return `${year}-${month}-${day}`;
};

export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}.${month}.${year}`;
};