import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserName  } from '../../shared/store/slices/nameSlice';
import { ReactComponent as NotificationIcon } from '../../shared/images/svg/notifications.svg';

import styles from './styles.module.scss';

export const Header = () => {
  const userName = useSelector(selectUserName);

  return (
    <header className={styles.header}>
      <div className={styles.info}>
        <NotificationIcon className={styles.notificationIcon} />
        <span className={styles.username}>{userName}</span>
      </div>
    </header>
  );
};
