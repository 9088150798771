import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../shared/store/slices/notificationSlice';
import { ReactComponent as VkIcon } from '../../../shared/images/svg/vk.svg';
import { ReactComponent as TgIcon } from '../../../shared/images/svg/tg.svg';
import { getSocialMedia, setSocialMedia } from '../../../api/profile';
import { useUnsavedChangesWarning } from '../../../shared/utils/useUnsavedChangesWarning';

import styles from './styles.module.scss';

const SOCIAL_MEDIA_TG = 'tg';
const SOCIAL_MEDIA_VK = 'vk';

export const SocialNetworks = () => {
    const [isDirty, setIsDirty] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const dispatch = useDispatch();
    const tgValue = watch(SOCIAL_MEDIA_TG);

    useEffect(() => {
      const subscription = watch((value, { name }) => {
        if (name) {
          setIsDirty(true);
        }
      });
  
      return () => subscription.unsubscribe();
    }, [watch]);
  
    useUnsavedChangesWarning(isDirty);

    useEffect(() => {
        getSocialMedia()
          .then((data) => {
            if (data) {
              setValue(SOCIAL_MEDIA_TG, data.tg || '');
              setValue(SOCIAL_MEDIA_VK, data.vk || '');
              setIsDirty(false);
            }
          })
          .catch((error) => {
            dispatch(addNotification(error.message));
          });
    }, []);
  
    const onSubmit = (data) => {
        setSocialMedia(data)
          .then((response) => {
              dispatch(addNotification(response));
              setIsDirty(false);
          })
          .catch((error) => {
              dispatch(addNotification(error.message));
          });
    };

    useEffect(() => {
      if (tgValue && tgValue.includes('@')) {
        const formattedValue = `https://t.me/${tgValue.replace('@', '')}`;
        setValue(SOCIAL_MEDIA_TG, formattedValue);
      }
    }, [tgValue, setValue]);
  
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Ссылки на страницы социальных сетей</h2>

        <div className={`baseField ${errors[SOCIAL_MEDIA_VK] ? 'baseFieldErrorLine' : ''}`}>
          <label htmlFor={SOCIAL_MEDIA_VK}><VkIcon />вставьте URL-адрес*</label>
            <input
              id={SOCIAL_MEDIA_VK}
              {...register(SOCIAL_MEDIA_VK, {
                  pattern: {
                      value: /^https:\/\/vk\.com\/[a-zA-Z0-9_]+$/,
                      message: 'Введите корректную ссылку на ВКонтакте (https://vk.com/username)',
                  },
              })}
              placeholder="https://vk.com/username"
            />
          {errors[SOCIAL_MEDIA_VK] && <p className='baseFieldError'>{errors[SOCIAL_MEDIA_VK].message}</p>}
        </div>

        <div className={`baseField ${errors[SOCIAL_MEDIA_TG] ? 'baseFieldErrorLine' : ''}`}>
            <label htmlFor={SOCIAL_MEDIA_TG}><TgIcon />вставьте URL-адрес*</label>
            <input
              id={SOCIAL_MEDIA_TG}
              {...register(SOCIAL_MEDIA_TG, {
                  pattern: {
                      value: /^https:\/\/t\.me\/[a-zA-Z0-9_]+$/,
                      message: 'Введите корректную ссылку на Telegram (https://t.me/username)',
                  },
              })}
              placeholder="https://t.me/username"
            />
            {errors[SOCIAL_MEDIA_TG] && <p className='baseFieldError'>{errors[SOCIAL_MEDIA_TG].message}</p>}
        </div>

  
        <div className={styles.button}>
          <Button type="submit">Сохранить</Button>
        </div>
      </form>
    );
}