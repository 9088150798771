export class AgentsDataMapper {    
    static toDomainModel(apiData) {
        return {
            totalAgents: apiData?.paid_agents_count,
            curator: {
                name: (apiData?.my_curator?.last_name || '') + ' ' + (apiData?.my_curator?.name || '') + ' ' + (apiData?.my_curator?.second_name || ''),
                id: apiData?.my_curator?.user_id,
            },
            agents: apiData?.agents?.map((agent) => {
                return {
                    date: new Date(agent?.registration_date || null),
                    name: agent?.name || '',
                    id: agent?.id,
                    profit: parseFloat(agent?.profit),
                    status: agent?.status,
                }
            }),
        };
    }
}

export class AgentDataMapper {    
    static toDomainModel(apiData) {
        return {
            name: apiData?.second_name || '',
            date: new Date(apiData?.registration_date || null),
            status: apiData?.status,
            profit: parseFloat(apiData?.profit),
            curator: apiData?.curator,
            totalAgents: apiData?.paid_agent_count,
            totalClients: apiData?.paid_client_count,
            tg: apiData?.tg,
            vk: apiData?.vk,
        };
    }
}