import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss'

export const BigCard = ({ icon: Icon, label, text, link }) => {
  return (
    <div className={styles.card}>
      <Icon />
      <span className={styles.label}>{label}</span>
      <span className={styles.text}>{text}</span>
      {link &&
        <NavLink to={link} className={styles.link}>
          Перейти в профиль
        </NavLink>
      }
    </div>
  );
};