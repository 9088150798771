import { useState } from 'react';
import { Button } from '../Button';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../shared/store/slices/notificationSlice';
import { credit, debit } from '../../api/finances';

import styles from './styles.module.scss';

export const BalanceChanger = ({refreshFinancesData}) => {
  const [activeTab, setActiveTab] = useState('debit');
  const [minWithdrawError, setMinWithdrawError] = useState('');
  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setMinWithdrawError('');
  };

  const onSubmit = (data) => {
    if (activeTab === 'credit' && Number(data.sum) < 5000) {
      setMinWithdrawError('Минимальная сумма вывода 5000 рублей');
      return;
    };

    if (data.sum) {
      if (activeTab === 'debit') {
        debit(data.sum)
          .then((response) => {
              if (response.url) {
                window.open(response.url, '_blank');
              }
              refreshFinancesData();
          })
          .catch((error) => {
              dispatch(addNotification(error.message));
          });
      } else {
        credit(data.sum)
          .then((response) => {
              dispatch(addNotification(response));
              refreshFinancesData();
          })
          .catch((error) => {
              dispatch(addNotification(error.message));
          });
      }
    };
  };

  const handleInput = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setValue('sum', value, { shouldValidate: true });
    setMinWithdrawError('');
  };

  return (
    <div className={styles.balanceChanger}>
      <div className={styles.balanceChangerInner}>
        <div className={styles.tabs}>
          <button
            className={`${styles.tab} ${activeTab === 'debit' ? styles.active : ''}`}
            onClick={() => handleTabClick('debit')}
          >
            Пополнить
          </button>
          <button
            className={`${styles.tab} ${activeTab === 'credit' ? styles.active : ''}`}
            onClick={() => handleTabClick('credit')}
          >
            Снять
          </button>
        </div>
        
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className="baseField">
            <label htmlFor="sum">Введите сумму</label>
            <input
              id="sum"
              inputMode="numeric"
              {...register('sum', {
                required: 'Это поле обязательно',
              })}
              onInput={handleInput}
            />
            {errors.sum && <p className='baseFieldError'>{errors.sum.message}</p>}
            {minWithdrawError && <p className='baseFieldError'>{minWithdrawError}</p>}
          </div>
          <Button type="submit">
            {activeTab === 'debit' ? 'Пополнить' : 'Снять'}
          </Button>
        </form>
      </div>
    </div>
  );
};
