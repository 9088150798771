import React from 'react';
import styles from './styles.module.scss'

export const SmallCard = ({ icon: Icon, label, text }) => {
    return (
      <div className={styles.card}>
        <Icon />
        <span className={styles.label}>{label}</span>
        <span className={styles.text}>{text}</span>
      </div>
    );
  };
