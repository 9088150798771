import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { MyData } from './components/MyData';
import { SocialNetworks } from './components/SocialNetworks';
import { Details } from './components/Details';
import { Security } from './components/Security';

import styles from './styles.module.scss';

export const Profile = () => {
  const location = useLocation();

  const renderForm = () => {
    switch (location.pathname) {
      case '/profile/info':
        return <MyData />;
      case '/profile/social-networks':
        return <SocialNetworks />;
      case '/profile/details':
        return <Details />;
      case '/profile/security':
        return <Security />;
    }
  };

  return (
    <div className={styles.profile}>
      <h1>Профиль</h1>

      <div className={styles.profileInner}>
        <div className={styles.nav}>
          <NavLink
            to="/profile/info"
            className={({ isActive }) => (isActive ? styles.active : '')}
          >
            Мои данные
          </NavLink>
          <NavLink
            to="/profile/social-networks"
            className={({ isActive }) => (isActive ? styles.active : '')}
          >
            Социальные сети
          </NavLink>
          <NavLink
            to="/profile/details"
            className={({ isActive }) => (isActive ? styles.active : '')}
          >
            Реквизиты
          </NavLink>
          <NavLink
            to="/profile/security"
            className={({ isActive }) => (isActive ? styles.active : '')}
          >
            Безопасность
          </NavLink>
        </div>

        <div className={styles.forms}>
          {renderForm()}
        </div>
      </div>
    </div>
  );
};
