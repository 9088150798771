import React from 'react';
import { Notifications } from '../Notifications';

import styles from './styles.module.scss';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <p>© {currentYear} Белый Слон. Все права защищены.</p>
      <Notifications />
    </footer>
  );
};
