import React, { useState, useEffect } from 'react';
import { SmallCard } from '../../components/SmallCard';
import { ReactComponent as BalanceIcon } from '../../shared/images/svg/balance.svg';
import { ReactComponent as AllTimeMoneyIcon } from '../../shared/images/svg/allTimeMoney.svg';
import { ReactComponent as ClientsIcon } from '../../shared/images/svg/clients2.svg';
import { ReactComponent as AgentsIcon } from '../../shared/images/svg/agents2.svg';
import { ReactComponent as PromocodeIcon } from '../../shared/images/svg/promocode.svg';
import { ReactComponent as LinkForClientsIcon } from '../../shared/images/svg/linkForClients.svg';
import { ReactComponent as LinkForAgentsIcon } from '../../shared/images/svg/linkForAgents.svg';
import { MiddleCard } from '../../components/MiddleCard';
import { useDispatch } from 'react-redux';
import { formatCurrency } from '../../shared/utils/formatCurrency';
import { addNotification } from '../../shared/store/slices/notificationSlice';
import { getUserStatistics } from '../../api/user';

import styles from './styles.module.scss'

export const MainPage = () => {
  const [balance, setBalance] = useState(null);
  const [balanceAllTime, setBalanceAllTime] = useState(null);
  const [clientCount, setClientCount] = useState(null);
  const [agentCount, setAgentCount] = useState(null);
  const [id, setId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getUserStatistics()
      .then((data) => {
        setBalance(data.balance);
        setBalanceAllTime(data.balanceAllTime);
        setClientCount(data.clientCount);
        setAgentCount(data.agentCount);
        setId(data.id);
      })
      .catch((error) => {
        dispatch(addNotification(error.message));
      });
  }, []);

  const firstRowData = [{
    icon: BalanceIcon,
    label: 'Текущий баланс',
    text: `${formatCurrency(balance)} ₽`,
  },{
    icon: AllTimeMoneyIcon,
    label: 'За все время',
    text: `${formatCurrency(balanceAllTime)} ₽`,
  },{
    icon: ClientsIcon,
    label: 'Клиентов',
    text: clientCount || '0',
  },{
    icon: AgentsIcon,
    label: 'Агентов',
    text: agentCount || '0',
  }];

  const secondRowData = [{
    icon: PromocodeIcon,
    label: 'Промокод',
    text: id || '0',
  },{
    icon: LinkForClientsIcon,
    label: 'Ссылка для будущих клиентов',
    link: `https://bslon-bankrotstvo.ru/?ref=${id}` || '',
  },{
    icon: LinkForAgentsIcon,
    label: 'Ссылка для будущих агентов',
    link: `${window.location.origin}/registration?ref=${id}` || '',
  }];

  return (
    <div className={styles.mainPage}>
      <div className={styles.info}>
        <div className={styles.image}>
          <img
            src="https://via.placeholder.com/537x679"
            alt="Описание изображения"
          />
        </div>
        <div className={styles.texts}>
          <h1>Дорогой друг!</h1>
          <p>
            Акция «Агенты ДолговНет» это отличная возможность помочь твоим родственникам, друзьям, знакомым и всем, 
            кто нуждается в помощи получить скидку на услуги финансовой компании «Белый Слон», а тебе получать денежные вознаграждения всего лишь за рекомендации!
          </p>
          <p>
            За каждого друга, заключившего договор и оплатившего более 20000 руб за услуги с финансовой компанией «Белый Слон», ты получишь по 7500 руб!
          </p>
          <p>
            Чем больше таких заключенных договоров – тем больше у тебя размер денежного вознаграждения, подробнее в видео!
          </p>
          <p>
            Также по твоей рекомендации твои друзья могут стать «Агентами ДолговНет» Ты будешь получать наставнический бонусы от доходов твоих партнеров!
          </p>
        </div>
      </div>

      <div className={styles.cards}>
        {firstRowData.map((data, index) => <SmallCard key={index} {...data} />)}
      </div>

      <div className={styles.cards}>
        {secondRowData.map((data, index) => <MiddleCard key={index} {...data} />)}
      </div>

    </div>
  );
};
